import React from 'react';

class Footer extends React.Component {
  render() {
    // const data = this.props;
    // console.log(data);
    return (
      <footer></footer>
      // <footer className="w-full text-center text-grey p-4 pin-b">
      // dsfsdsdffds
      // </footer>
    );
  }
}

export default Footer;
