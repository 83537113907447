import React from 'react';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import './layout.css';
import Footer from '../components/footer';;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          htmlAttributes={{
            lang: 'en',
          }}
          bodyAttributes={{
            class: '',
          }}
        />
        <div className="container mx-auto px-4">{children}</div>
        <Footer data={data} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
